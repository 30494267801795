import { Box, Group, Select, Text } from '@mantine/core'
import { IconWorld } from '@tabler/icons'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { useLocaleContext } from '../../context/locale'
import countries from '../../utils/countries.json'

export const hitsCountries = [
  'ID',
  'AR',
  'IT',
  'PE',
  'GB',
  'KR',
  'TH',
  'CO',
  'TR',
  'US',
  'CA',
  'FR',
  'BR',
  'DE',
  'ES',
  'MX',
  'AU',
  'CL',
  'IN'
]

const data = countries.map((c) => ({
  label: c.name,
  value: c.code.toLowerCase(),
  flag: c.emoji
}))

const SelectItem = forwardRef(({ label, flag, ...others }, ref) => (
  <Box ref={ref} {...others}>
    <Group align="baseline">
      <Text>{flag}</Text>
      <Text>{label}</Text>
    </Group>
  </Box>
))

SelectItem.displayName = 'SelectItem'
SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired
}

const CountryInput = ({
  withLabel = true,
  onChange,
  value,
  filterCountries,
  ...other
}) => {
  const { country, setCountry } = useLocaleContext()

  const filteredData = filterCountries
    ? data.filter((item) => filterCountries.includes(item.value.toUpperCase()))
    : data.filter((item) => hitsCountries.includes(item.value.toUpperCase()))

  return (
    <Select
      icon={<IconWorld size={20} />}
      placeholder="Pick one"
      nothingFound="No options"
      data={filteredData}
      itemComponent={SelectItem}
      value={value || country}
      onChange={onChange || setCountry}
      {...other}
      dropdownPosition="bottom"
      withinPortal
      styles={(theme) => ({
        input: {
          fontSize: theme.fontSizes.sm,
          backgroundColor: '#121E274D',
          padding: '4px 8px',
          borderRadius: '10px',
          border: 'none',
          borderBottom: '2px solid #7B39B6',
          opacity: 1,
          position: 'relative'
        },
        dropdown: {
          zIndex: 1000
        },
        item: {
          fontSize: theme.fontSizes.xs
        }
      })}
    />
  )
}

CountryInput.propTypes = {
  withLabel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  filterCountries: PropTypes.arrayOf(PropTypes.string),
  other: PropTypes.object
}

export default CountryInput
